import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import { Chance } from "chance";
import jwtDecode from "jwt-decode";

// reducer - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";

// project imports
import Loader from "ui-component/Loader";
import axios from "utils/axios";
import Axios from "axios";
import { API_URL, APP_URL } from "config";

const SERVER_API_URL = API_URL;
const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  return decoded.exp > Date.now() / 1000;
};




const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem("serviceToken", serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem("serviceToken");
    localStorage.removeItem("users");
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);



export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem("serviceToken");
        if (serviceToken && verifyToken(serviceToken)) {
          setSession(serviceToken);
          const response = await axios.get("/api/account/me");
          const { user } = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user,
            },
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email, password, RememberMe) => {
    if (RememberMe === true) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
    }
    if (RememberMe === false) {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    const response = await Axios.post(SERVER_API_URL + "/api/auth/signin", {
      email,
      password,
    });

    if (response.data.status !== "200") {
      return response.data;
    } else if (response.data.status === "200") {
      const serviceToken = response.data.data.accesstoken;

      setSession(serviceToken);
      let user = {
        id: response.data.data.id,
        email: response.data.data.email,
        name: response.data.data.email,
        groupId: response.data.data.groupId,
        role_id: response.data.data.role_id,
		cn_role_id: response.data.data.cn_role_id,
      };
      window.localStorage.setItem("users", JSON.stringify(user));
      window.location.href = "/CommunityDashboard";
      /* dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user,
      },
      }); */

      return response.data;
    }
  };

  const delay = (timeout) => new Promise((res) => setTimeout(res, timeout));

  const register = async (email, password, firstName, lastName, mobile) => {
    try {
      await delay(500);

      if (!email || !password) {
        return [400, { message: "Enter Your Email & Password" }];
      }

      if (!firstName || !lastName) {
        return [400, { message: "Enter Your Name" }];
      }

      const id = chance.bb_pin();

      const response = await Axios.post(SERVER_API_URL + "/api/auth/signup", {
        id,
        email,
        password,
        firstName,
        lastName,
        mobile,
      });

      return response.data;
    } catch (err) {
      return [500, { message: "Server Error" }];
    }
  };

  const registerOtpVerify = async (regUserId, otp) => {
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/registerotpverify", {
      id,
      regUserId,
      otp,
    });

    return response.data;
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };



  const verifyotp = async (UserId, otp) => {
    try {
      const ServerResponseNext = await Axios.post(
        SERVER_API_URL + "/api/account/forgot/verifyotp",
        { UserId, otp }
      );

      let userRes = [];
      if (
        ServerResponseNext.data.status &&
        ServerResponseNext.data.status === "200"
      ) {
        userRes = [200, ServerResponseNext.data];
      } else {
        userRes = [201, ServerResponseNext.data];
      }
      return userRes;
    } catch (err) {
      console.error(err);
      return [500, { message: "Server Error" }];
    }
  };

  const newPassword = async (UserId, Password) => {
    try {
      const ServerResponseNext = await Axios.post(
        SERVER_API_URL + "/api/auth/reset_password",
        { UserId, Password }
      );

      let userRes = [];
      if (
        ServerResponseNext.data.status &&
        ServerResponseNext.data.status === "200"
      ) {
        userRes = [200, ServerResponseNext.data];
      } else {
        userRes = [201, ServerResponseNext.data];
      }
      return userRes;
    } catch (err) {
      console.error(err);
      return [500, { message: "Server Error" }];
    }
  };

 // - - - - - Profile - - - - - 

 const updateProfile = async (fname, lname, city, tradingviewIid, address, occupation, userId) => {
  const serviceToken = window.localStorage.getItem("serviceToken");
  axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  const response = await axios.post("/api/user/profile_update", {fname, lname, city, tradingviewIid, address, occupation,   userId});
  verifyAuthorization(response);
  return response.data;
};

const Alloccupationlist = async () => {
  const serviceToken = window.localStorage.getItem("serviceToken");
  axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;  
  const response = await axios.post("/api/Profile/Alloccupationlist");
  verifyAuthorization(response);
  return response;
};

const resetPassword = async (email) => {
  try {
    const ServerResponseNext = await Axios.post(SERVER_API_URL + "/api/account/forgot", { email });
    let userRes = [];
    if (
      ServerResponseNext.data.status &&
      ServerResponseNext.data.status === "200"
    ) {
      userRes = [200, ServerResponseNext.data.data];
    } else {
      userRes = [201, ServerResponseNext.data];
    }
    return userRes;
  } catch (err) {
    console.error(err);
    return [500, { message: "Server Error" }];
  }
};

















  const Profiledata = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/profile_view", { userId });
    verifyAuthorization(response);
    return response.data;
  };

  const SociallinkView = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/Sociallink_view", { userId });
    verifyAuthorization(response);
    return response;
  };


  const updateSociallink = async (
    about,
    instaLink,
    fbLink,
    twitterLink,
    linkedinLink,
    userId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/Sociallinkupdate", {
      about,
      instaLink,
      fbLink,
      twitterLink,
      linkedinLink,
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const AllUsers = async (
    userId,
    search,
    GroupFilterstate,
    searchfilterstate
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    // alert(searchfilterstate + 'i am jwt');
    const response = await axios.post("/api/user/AllUsers", {
      userId,
      search,
      GroupFilterstate,
      searchfilterstate,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const Addfriend = async (userId, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/follow_member", {
      userId,
      followUserId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const Removefriend = async (userId, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/unfollow_member", {
      userId,
      followUserId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const MyFollower = async (userId, search, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/followers_list", {
      userId,
      search,
    });
    verifyAuthorization(response);
    return response;
  };

  const MyFriends = async (userId, search, followUserId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/following_list", {
      userId,
      search,
    });
    verifyAuthorization(response);
    return response;
  };

  const MyGrouplist = async (userId, groupId, search) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/mygroup_members_list", {
      userId,
      groupId,
      search,
    });
    verifyAuthorization(response);
    return response;
  };
  const Statistics = async (userId, memberId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/members_statistics", {
      userId,
      memberId,
    });
    verifyAuthorization(response);
    return response;
  };
  const MemberProfile = async (userId, memberId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/view_member_profile", {
      userId,
      memberId,
    });
    verifyAuthorization(response);
    return response;
  };

  const Qscore = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/Qscore", {
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const Rewards_chart = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/rewardschart", { userId });
    verifyAuthorization(response);
    return response.data;
  };

  const watchlist = async (userId,watchType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/watch_list", {
      userId,watchType,
    });
    verifyAuthorization(response);
    return response;
  };

  const corewatchlist = async (userId, watchType) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/getcompanylistCore",
      { userId, watchType }
    );
    return response;
  };
  const coreInsertwatchlist = async (
    companyId,
    userId,
    watchtype,
    currentDate,
    currentyear,
    role_id
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/coreInsertwatchlist",
      {
        companyId,
        userId,
        watchtype,
        currentDate,
        currentyear,
        role_id,
      }
    );

    return response;
  };
  const corewatchlisthistory = async (
    userId,
    weeknum,
    CurrentYear,
    searchTerm,
    startDate,
    endDate
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/Getdata ",
      {
        userId,
        weeknum,
        CurrentYear,
        searchTerm,
        startDate,
        endDate
      }
    );

    return response;
  };
  const corewatchlisthistoryAdmin = async (
    userId,
    searchTerm,
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/GetdataAdmin ",
      {
        userId,
        searchTerm,
      }
    );

    return response;
  };
  const DeleteCoreWatchlist = async (userId, wid, companyid) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/deletecorewatchlisthistory",
      {
        userId,
        wid,
        companyid,
      }
    );
    return response;
  };

  const EditCoreWatchlistgetdata = async (userId, watchId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/EditGetdata",
      {
        userId,
        watchId,
      }
    );
    return response;
  };

  const Insertwatchlist = async (
    companyId,
    fincode,
    userId,
    watchtype,
    currentDate,
    currentyear
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/addwatchlist",
      {
        companyId,
        fincode,
        userId,
        watchtype,
        currentDate,
        currentyear,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const weekly = async (userId, watchType, weeknum) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/weekly", {
      userId,
      watchType,
      weeknum,
    });
    // const { serviceToken, user } = response.data;
    verifyAuthorization(response);
    return response;
  };

  const weeklist = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/weeklist");
    // const { serviceToken, user } = response.data;
    verifyAuthorization(response);
    return response;
  };

  const DeleteWatchlist = async (userId, wid, companyid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/deletewatchlisthistory",
      {
        userId,
        wid,
        companyid,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  //SECTOR WATCHLIST
  const SectorsList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL +"/api/user/sectorslist", {
      userId
    });
    verifyAuthorization(response);
    return response;
  };

  const InsertSectorwatchlist = async (
    userId,
    sectorID,
    currentDate,
    currentyear
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/addSectorwatchlist",
      {
        userId,
        sectorID,
        currentDate,
        currentyear,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Sectorwatchlistdata = async (userId, weeknum, CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/getSectorWatchlistHistory",
      {
        userId,
        weeknum,
        CurrentYear,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const DeleteSectorWatchlist = async (userId, wid, sectorID) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/deleteSectorwatchlisthistory",
      {
        userId,
        wid,
        sectorID,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const UserSectorRewards= async (userId,user_Id,year,week) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/SateliteWatchlist/UserSectorRewards",{userId,user_Id,year,week});
    verifyAuthorization(response);
    return response;
  };

  const HallOfFame = async () => {
    try {
      const serviceToken = window.localStorage.getItem("serviceToken");
      Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
      const response = await Axios.get(SERVER_API_URL + "/api/user/hallOfFame");
      verifyAuthorization(response); // Assuming this is defined elsewhere
      return response.data; // Return only the data part of the response
    } catch (error) {
      console.error("Error fetching Hall of Fame data:", error);
      throw error; // Throw the error to be handled by the calling function
    }
  };


  const Resourceslist = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/Resources");
    verifyAuthorization(response);
    return response;
  };
  const ResearchList = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/Research");
    verifyAuthorization(response);
    return response;
  };

  const TemplateList = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/Templates");
    verifyAuthorization(response);
    return response;
  };

  const TechnoList = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/Techno");
    verifyAuthorization(response);
    return response;
  };
  const ApplicationsList = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/apps");
    verifyAuthorization(response);
    return response;
  };

  const LearningList = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Learnings");
    verifyAuthorization(response);
    return response;
  };

  const GetLearing = async (userId, topicType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/GetLearing", {
      userId,
      topicType,
    });
    verifyAuthorization(response);
    return response;
  };

  const DeleteLearningHistory = async (userId, topicid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/LearnPath/DeleteLearningHistory",
      { userId, topicid }
    );
    verifyAuthorization(response);
    return response;
  };

  const InsertLearningHistory = async (userId, topicid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/InsertLearningHistory",
      { userId, topicid }
    );
    verifyAuthorization(response);
    return response;
  };

  const CheckTotalCountOfLearning = async (userId, topicType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/LearnPath/CheckTotalCountOfLearning",
      { userId, topicType }
    );
    verifyAuthorization(response);
    return response;
  };

  const CheckStatusOfLearning = async (userId, topicType) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/CheckStatusOfLearning",
      { userId, topicType }
    );
    verifyAuthorization(response);
    return response;
  };



  const ProcessUpdate = async (profileProcess, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/Profile/processUpdate", {
      profileProcess,
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const watchlistdata = async (userId, weeknum, CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/getwatchlisthistory",
      {
        userId,
        weeknum,
        CurrentYear,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const vivekwatchlistdata = async (userId, weeknum, CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/watchlist/getvivekwatchlisthistory",
      {
        userId,
        weeknum,
        CurrentYear,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const CoreauditCashdatalist = async (userId, quarterly, CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/getcoreauditCash",
      { userId, quarterly, CurrentYear }
    );
    verifyAuthorization(response);
    return response;
  };

  const UpdateCoreauditCashdata = async (
    userId,
    quarterly,
    curyear,
    onInvesting,
    onCurrent,
    returnVal,
    comment
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/updatecoreauditCash",
      {
        userId,
        quarterly,
        curyear,
        onInvesting,
        onCurrent,
        returnVal,
        comment,
      }
    );

    verifyAuthorization(response);
    return response;
  };

  const Coreauditcompanylist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/getcoreauditcompanylist",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const Coreauditdatalist = async (userId, userIds, quarterly, CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/getcoreaudit",
      { userId, userIds, quarterly, CurrentYear }
    );
    verifyAuthorization(response);
    return response;
  };

  const updateCoreAudit = async (
    onInvesting,
    onCurrent,
    returnVal,
    comment,
    HP,
    userId,
    companyId,
    currentQuarter, 
    CurrentYear
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/coreauditupdate",
      {
        onInvesting,
        onCurrent,
        returnVal,
        comment,
        HP,
        userId,
        companyId,
        currentQuarter, 
        CurrentYear
      }
    );
    verifyAuthorization(response);
    return response.data;
  };

  //Rolling Rewards
  const RewardsRollingInsert = async (
    userId,
    usersId,
    loginValue,
    habitValue
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken"); // Assuming this is your authentication token
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;

    try {
      const response = await Axios.post(
        SERVER_API_URL + "/api/rewards/rollingRewardsInsert",
        {
          userId,
          user_id: usersId, // Assuming you have the user ID here, replace with the actual user ID
          habit_days: parseInt(habitValue), // Ensure values are integers
          login_days: parseInt(loginValue),
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in RewardsRollingInsert:", error);
      throw error;
    }
  };

  const RewardsRollingFetchData = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken"); // Assuming this is your authentication token
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    try {
      const response = await Axios.get(
        SERVER_API_URL + "/api/rewards/rollingRewardsFetch"
      );
      console.log("<<>>", response.data.data[0]); // Check fetched data in console
      return response;
    } catch (error) {
      console.error("Error fetching stored data:", error);
    }
  };
  //Rolling Rewards

  const insertCoreAudit = async (userId, companyId, quarterly) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/coreauditinsert",
      { userId, companyId, quarterly }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const deleteCoreAudit = async (userId, companyId, quarterly,  CurrentYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreaudit/coreauditDelete",
      { userId, companyId, quarterly,  CurrentYear }
    );
    verifyAuthorization(response);
    return response.data;
  };

  // Rituals
  const WeeklyRitualsQuestions = async (userId, Status1, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/weeklyritualsquestions",
      { userId, Status1, User_Id }
    );
    verifyAuthorization(response);
    return response;
  };

  const WeeklyRitualsAnswer = async (userId, data, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/weeklyritualsquestions_answers",
      {
        userId,
        data,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const WeeklyRitualsAnswerCheck = async (userId, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/weeklyritualsquestions_answers_check",
      {
        userId,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const QuarterlyRitualsQuestions = async (userId, Status1, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/quarterlyritualsquestions",
      {
        userId,
        Status1,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const QuarterlyRitualsAnswer = async (userId, data, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/quarterlyritualsquestions_answers",
      {
        userId,
        data,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const QuarterlyRitualsAnswerCheck = async (userId, User_Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/quarterlyritualsquestions_answers_check",
      {
        userId,
        User_Id,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  //Rituals

  const Quantum_Quiz_Questions = async (Status1, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/Quantum", {
      Status1,
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const Quantum_insertdata = async (User_id, question, email, questionIds) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/Quantum_insertdata",
      { User_id, question, email, questionIds }
    );
    verifyAuthorization(response);
    return response;
  };
  const checkuserIddata = async (User_Id, weekNumber) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/checkuserIddata",
      { User_Id, weekNumber }
    );
    verifyAuthorization(response);
    return response;
  };
  const checkanswerdata = async (User_Id, weekNumber) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/checkanswerdata",
      { User_Id, weekNumber }
    );
    verifyAuthorization(response);
    return response;
  };
  const charitylistdata = async (userid, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/charity_listdata",
      { userid, userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const deletecharitydata = async (userId, nameCom, userid, month, amount) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/delete_charitylistdata",
      { userId, nameCom, userid, month, amount }
    );
    verifyAuthorization(response);
    return response;
  };
  const Insertcharitylist = async (
    charityid,
    userid,
    year,
    month,
    amount,
    addedon,
    monthid
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/insertcharity_list",
      { charityid, userid, year, month, amount, addedon, monthid }
    );
    verifyAuthorization(response);
    return response;
  };
  const charitylist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/charity_list",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const Editcharitydata = async (
    charityListid,
    year1,
    month,
    amount,
    charityList,
    userId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/editcharitydata",
      { charityListid, year1, month, amount, charityList, userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const weekdata1 = async (charityid, userid, watchtype) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/user/weekdata1", {
      charityid,
      userid,
      watchtype,
    });
    // const { serviceToken, user } = response.data;
    verifyAuthorization(response);
    return response;
  };

  const Email = async (userId, userIds) => {
    try {
      const serviceToken = window.localStorage.getItem("serviceToken");
      Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
      const response = await Axios.post(SERVER_API_URL + "/api/user/Email", {
        userId,
        userIds,
      });
      verifyAuthorization(response);
      return response.data;
    } catch (err) {
      return [500, { message: "Server Error" }];
    }
  };

  const TopTwentyuserDetailsBaseOnRewaerdPoint = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/TopTwentyuserDetailsBaseOnRewaerdPoint",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const TrendingCorePortfolio = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/TrendingCorePortfolio",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const TrendingSatelliteWatchlist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/TrendingSatelliteWatchlist",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const SW_One_Percent = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/SW_One_Percent",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const sectorWatchlist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/sectorWatchlist",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const RewardsPoint = async (userId, userIds) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/RewardsPoint", {
      userId,
      userIds,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const Pastevent = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/event/Pastevent", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };
  const Futurevent = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/Futurevent",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const Todayevent = async (userId, Eventuser_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/Todayevent",
      { userId, Eventuser_id }
    );
    verifyAuthorization(response);
    return response;
  };
  const GetAttendance = async (userId, groupId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/Attendance",
      { userId, groupId }
    );
    verifyAuthorization(response);
    return response;
  };
  const InsertAttendance = async (
    userId,
    scheduleId,
    eventId,
    attendanceClickedButton,
    event_type
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/InsertEventTracking",
      { userId, scheduleId, eventId, attendanceClickedButton,event_type }
    );
    verifyAuthorization(response);
    return response;
  };
  const AttendanceChartData = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/AttendanceChartData",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const updateVideoLink = async (
    userId,
    eventId,
    EditVideoLink,
    EditVideoLink2,
    EditVideoCompanyName1,
    EditVideoCompanyName2,
    EditVideoPassword1,
    EditVideoPassword2
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/updatevideolink",
      {
        userId,
        eventId,
        EditVideoLink,
        EditVideoLink2,
        EditVideoCompanyName1,
        EditVideoCompanyName2,
        EditVideoPassword1,
        EditVideoPassword2,
      }
    );
    verifyAuthorization(response);
    return response.data;
  };
  const CalendarEventsList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/CalendarEventsList",
      { userId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const profilevisibilityInsert = async (userId, module) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/profile_visibility_insert",
      { userId, module }
    );
    verifyAuthorization(response);
    return response;
  };

  const profilevisibility = async (userId, userIds) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/profile_visibility",
      { userId, userIds }
    );
    verifyAuthorization(response);
    return response;
  };

  const UserDetails = async (userId, search, GroupFilterstate) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/userdetail",
      { userId, search, GroupFilterstate }
    );
    verifyAuthorization(response);
    return response;
  };

  const Editdatauser = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/Editdatauser",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditSavedata = async (
    userId,
    ValueCheck,
    emaildata,
    GroupIddata,
    StartDate,
    fnamedata,
    lastnamdata,
    Phonenumber,
    EndDate,
    Badgedata,
    Statusdata
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/Editsavedata",
      {
        userId,
        ValueCheck,
        emaildata,
        GroupIddata,
        StartDate,
        fnamedata,
        lastnamdata,
        Phonenumber,
        EndDate,
        Badgedata,
        Statusdata,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Adddatauser = async (
    userId,
    Addfnamedata,
    Addlnamedata,
    Addemaildata,
    Addmobilenumberdata,
    AddGroupiddata,
    AddBadgedata,
    AddCitydata,
    Addaddressdata,
    Addaboutdata,
    AddSubscriptionstartdata,
    AddSubscriptionenddata,
    Addstatusdata,
    Addmembersincedata
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/user_management/Adddatauser",
      {
        userId,
        Addfnamedata,
        Addlnamedata,
        Addemaildata,
        Addmobilenumberdata,
        AddGroupiddata,
        AddBadgedata,
        AddCitydata,
        Addaddressdata,
        Addaboutdata,
        AddSubscriptionstartdata,
        AddSubscriptionenddata,
        Addstatusdata,
        Addmembersincedata,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const Csvreport = async (userId, module, date) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/Csvreport",
      { userId, module, date }
    );
    return response;
  };

  const CharityReport = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/CharityReport",
      { userId, value }
    );
    return response;
  };
  const Watchlist = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/SatelliteWatchListReport",
      { userId, value }
    );
    return response;
  };

  const LoginReport = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/LoginReport",
      { userId, value }
    );
    return response;
  };

  const QuantumQuizdata = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/QuantumQuiz",
      { userId, value }
    );
    return response;
  };
  const NotloginReportdata = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/NotLoginReport",
      { userId, value }
    );
    return response;
  };
  const CoreAuditReportdata = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/CoreAuditReport",
      { userId, value }
    );
    return response;
  };

  const EventList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/event/EventList", {
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };

  const GroupnameList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/GroupnameList",
      { userId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const AddEvent = async (
    eventId,
    eventStartFormatted,
    eventEndFormatted,
    userTimeZone,
    eventStart,
    eventEnd,
    groupId,
    userId,
    considerAttendance,
    Description,
    EventName,
    Link,
    Recurrencevalue,
    occurrences,
    weekarr,
    eid,
    Event_Type,
    event_type_id,
    Presented_by_Id
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/event/AddEvent", {
      eventId,
      eventStartFormatted,
      eventEndFormatted,
      userTimeZone,
      eventStart: eventStartFormatted,
      eventEnd: eventEndFormatted,
      groupId,
      userId,
      considerAttendance,
      Description,
      EventName,
      Link,
      Recurrencevalue,
      occurrences,
      weekarr,
      eid,
      Event_Type,
      event_type_id,
      Presented_by_Id,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const GetEventsList = async (userId, search, FilterGroup, timeline) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/GetEventsList",
      { userId, search, FilterGroup, timeline }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const DeleteSchedule = async (userId, Id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/DeleteSchedule",
      { userId, Id }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const DeleteAllSchedule = async (userId, EventId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/DeleteAllSchedule",
      { userId, EventId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const updateEvent = async (
    eventId,
    eventEnd,
    Description,
    Link,
    userId,
    eventStart,
    groupId,
    EditEvent_consider_Attendance,
    EId,
    Name,
    event_type_id,
    EditEventType,
    Presented_by_Id
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/updateEvent",
      {
        eventId,
        eventEnd,
        Description,
        Link,
        userId,
        eventStart,
        groupId,
        EditEvent_consider_Attendance,
        EId,
        Name,
        event_type_id,
        EditEventType,
        Presented_by_Id,
      }
    );
    verifyAuthorization(response);
    return response.data;
  };
  //stock
  const stockInsert = async (
    userId,
    alert_name,
    companyId,
    stop_loss,
    profit_target,
    expiry_date
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/stockInsert",
      { userId, alert_name, companyId, stop_loss, profit_target, expiry_date }
    );
    verifyAuthorization(response);
    return response;
  };
  const stocklist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/stocklist",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const StockAlertList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/StockAlertList",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const stockalertUpdate = async (
    userId,
    alert_id,
    stop_loss,
    expiry_date,
    profit_target,
    alert_name,
    status
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/stockalertUpdate",
      {
        userId,
        alert_id,
        stop_loss,
        expiry_date,
        profit_target,
        alert_name,
        status,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const stockalertDelete = async (userId, alert_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/event/stockalertDelete",
      { userId, alert_id }
    );
    verifyAuthorization(response);
    return response;
  };
  const stockalertviewdata = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/stockChart", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const Getcategory = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/getcategorylist", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };

  const ContactInsert = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    files,
    ext
  ) => {
    const data = new FormData();
    let response = "";
    let fileName = "";
    if (files.length !== 0) {
      data.append("file", files[0]);
      const ext = files[0].name.substring(files[0].name.lastIndexOf(".") + 1);

      if (ext === "pdf" || ext === "png" || ext === "jpg" || ext === "jpeg") {
        const upload = await Axios.post(
          SERVER_API_URL + "/uploadAttachment",
          data
        ).then((upload) => {
          fileName = upload.data[0].filename;
          response = InsertWithLogin(
            userId,
            category,
            email,
            mobile,
            subject,
            content,
            fileName
          );
        });
      }
      return response;
    } else {
      response = InsertWithLogin(
        userId,
        category,
        email,
        mobile,
        subject,
        content,
        fileName
      );
      //  verifyAuthorization(response);
      return response;
    }
  };
  const GetcategoryWithoutLogin = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/getcategorylistWithoutlogin",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const ContactInsertWithoutLogin = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    files
  ) => {
    const data = new FormData();
    let response = "";
    let fileName = "";
    if (files.length !== 0) {
      data.append("file", files[0]);
      const ext = files[0].name.substring(files[0].name.lastIndexOf(".") + 1);
      if (ext === "pdf" || ext === "png" || ext === "jpg" || ext === "jpeg") {
        const upload = await Axios.post(
          SERVER_API_URL + "/uploadAttachment",
          data
        ).then((upload) => {
          fileName = upload.data[0].filename;
          response = Insert(
            userId,
            category,
            email,
            mobile,
            subject,
            content,
            fileName
          );
        });
      }
      return response;
    } else {
      response = Insert(
        userId,
        category,
        email,
        mobile,
        subject,
        content,
        fileName
      );

      return response;
    }
  };

  const Insert = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    fileName
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/contactInsertWithoutlogin",
      { userId, category, email, mobile, subject, content, fileName }
    );

    return response;
  };

  const EventReport = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/EventlatestReport",
      { userId, value }
    );
    return response;
  };

  const AlertReport = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/report/AlertReport",
      { userId, value }
    );
    return response;
  };

  const InsertWithLogin = async (
    userId,
    category,
    email,
    mobile,
    subject,
    content,
    fileName
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/contactInsert", {
      userId,
      category,
      email,
      mobile,
      subject,
      content,
      fileName,
    });

    verifyAuthorization(response);
    return response;
  };
  const GetdataHelpManagement = async (userId, filter, search, category) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/contact/GetContactData",
      { userId, filter, search, category }
    );
    verifyAuthorization(response);
    return response;
  };

  const GetcategorylistTool = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/getcategorylistTool ",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const UpdateResponse = async (
    userId,
    message,
    PrimaryId,
    status,
    userIds,
    email
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/UpdateResponse", {
      userId,
      message,
      PrimaryId,
      status,
      userIds,
      email,
    });
    verifyAuthorization(response);
    return response;
  };
  const Displayresponse = async (userId, userid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Displayresponse", {
      userId,
      userid,
    });
    verifyAuthorization(response);
    return response;
  };

  const InsertHabit = async (
    userId,
    date,
    habit_id,
    users_Id,
    freq,
    Year,
    Month
  ) => {
    console.log(date, habit_id, users_Id, freq, Year);
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/InsertHabit",
      { userId, date, habit_id, users_Id, freq, Year, Month }
    );
    return response;
  };

  const GetHabitsList = async (userId, curmonth) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/GetHabitsList",
      { userId, curmonth }
    );
    return response;
  };
  const Gethabitsdata = async (userId, curmonth, curyear, user_id) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/Gethabitsdata",
      { userId, curmonth, curyear, user_id }
    );
    return response;
  };

  const handleChangedeletedata = async (userId, habit_id, usersId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/Habitdeletedata",
      { userId, habit_id, usersId }
    );
    return response;
  };
  const habits = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/habit/habits", {
      userId,
    });
    verifyAuthorization(response);
    return response.data;
  };
  const habitchart = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/habitcharts"
    );
    verifyAuthorization(response);
    return response.data;
  };
  const CheckStatusOfHabit = async (curyear, curmonth, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/HabitsChart",
      { curyear, curmonth, userId }
    );
    verifyAuthorization(response);
    return response.data;
  };
  const MonthlyLineChart = async (curyear, curmonth, userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/HabitsMonthChart",
      { curyear, curmonth, userId }
    );
    verifyAuthorization(response);
    return response.data;
  };

  const GetHabitsNamelist = async (userId, UserId, Month, Year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/habit/GetHabitsNamelist",
      { userId, UserId, Month, Year }
    );
    verifyAuthorization(response);
    return response;
  };
  const CatcusDeleteCoreWatchlist = async (userId, wid, companyid) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/Catcusdeletecorewatchlisthistory",
      { userId, wid, companyid }
    );
  };

  const Catcuswatchlisthistory = async (userId, weeknum, CurrentYear) => {
    console.log("set", userId, weeknum, CurrentYear);
    const response = await Axios.post(
      SERVER_API_URL + "/api/coreWatchlist/CatcusWatchlistGetdata",
      {
        userId,
        weeknum,
        CurrentYear,
      }
    );

    return response;
  };

  const GetAllCompanyforStockAlert = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/GetStockalertcompany",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  // const CreateStockalert = async (alert_name,userId,companyId,profit_target,stop_loss,expiry_date) => {
  //   const serviceToken = window.localStorage.getItem("serviceToken");
  //   axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  //   const response = await Axios.post(SERVER_API_URL+ "/api/stockAlert/CreateStockalert",{alert_name,userId,companyId,profit_target,stop_loss,expiry_date});
  //   verifyAuthorization(response);
  //   return response;
  // };
  const CreateStockalert = async (
    alert_name,
    userId,
    companyId,
    profit_target,
    stop_loss_per,
    profit_target_per,
    stop_loss,
    expiry_date
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/CreateStockalert",
      {
        alert_name,
        userId,
        companyId,
        profit_target,
        stop_loss_per,
        profit_target_per,
        stop_loss,
        expiry_date,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const GetStockalertCount = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/GetStockalertCount",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const GetStockalertList = async (userId, status) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/GetStockalertList",
      { userId, status }
    );
    verifyAuthorization(response);
    return response;
  };

  const DeleteExpired = async (userId, status) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/DeleteExpired",
      { userId, status }
    );
    verifyAuthorization(response);
    return response;
  };

  const StockAlertDelete = async (userId, id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/alertDelete",
      { userId, id }
    );
    verifyAuthorization(response);
    return response;
  };

  // const UpdateStockAlert = async (id,stop_loss,expiry_date,profit_target,alert_name) => {
  //   const serviceToken = window.localStorage.getItem("serviceToken");
  //   axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  //   const response = await Axios.post(SERVER_API_URL+ "/api/stockAlert/UpdateStockAlert",{id,stop_loss,expiry_date,profit_target,alert_name});
  //   verifyAuthorization(response);
  //   return response;
  // };
  const GetMarketcategory = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/market/GetMarketcategory",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const GetMarketchartdata = async (userId,market_index_type,IndexTypes) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/market/chartdata",
      { userId,market_index_type,IndexTypes }
    );
    verifyAuthorization(response);
    return response;
  };
  const GetSectorchartdata = async (userId,sector_index_type,IndexTypes) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/market/sector_chartdata",
      { userId,sector_index_type,IndexTypes }
    );
    verifyAuthorization(response);
    return response;
  };
  const GetSectortabledata = async (userId,sector_index_type) => {
  // const GetMarketchartdata = async (userId,market_index_type,IndexTypes) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/market/sector_tabledata",
      { userId,sector_index_type }
    );
    verifyAuthorization(response);
    return response;
  };

  const UpdateStockAlert = async (
    userId,
    id,
    stop_loss,
    stop_loss_per,
    expiry_date,
    profit_target,
    profit_target_per,
    alert_name
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/stockAlert/UpdateStockAlert",
      {
        userId,
        id,
        stop_loss,
        stop_loss_per,
        expiry_date,
        profit_target,
        profit_target_per,
        alert_name,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  function verifyAuthorization(response) {
    console.log(response.data.statuscode);
    if (response.data.statuscode === 401) {
      logout();
      window.location.href = APP_URL + "/login";
    } else {
      return true;
    }
  }

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  const Gamefications = async (userId, days) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Gamefication", {
      userId,
      days,
    });
    verifyAuthorization(response);
    return response;
  };

  const Onboarding = async (userId, id, groupId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/OnBoarding", {
      userId,
      id,
      groupId,
    });
    verifyAuthorization(response);
    return response;
  };
  const OnboardingCheckList_Added = async (
    id,
    title_id,
    sub_id,
    Marks
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/OnBoarding/OnboardingCheckList_Added",
      { id, title_id, sub_id,Marks }
    );
    verifyAuthorization(response);
    return response;
  };
  const OnboardingCheckList_Delete_OR_Edit = async (
    id,
    title_id,
    sub_id,Marks
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/OnBoarding/OnboardingCheckList_Delete_OR_Edit",
      { id, title_id, sub_id,Marks }
    );
    verifyAuthorization(response);
    return response;
  };
  const Marketdata = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/get_nse_marketdata");
    verifyAuthorization(response);
    return response;
  };

  const Insertedata = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/nse_marketdata");
    verifyAuthorization(response);
    return response;
  };
    const UserDetailsQic = async (
    userId,
    search,
    SEmail,
    SHackothonFinisher,
    STechnofundaAward,
    SGroup,
    SCommunityDuration,
    SIndustry,
    STeamName,
    STeamPosition,
    GroupFilterstate
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/userdetailQic",
      {
        userId,
        search,
        SEmail,
        SHackothonFinisher,
        STechnofundaAward,
        SGroup,
        SCommunityDuration,
        SIndustry,
        STeamName,
        STeamPosition,
        GroupFilterstate,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const MemberDetails = async (teamname,event_year, currentActiveEvent) => {
    const response = await Axios.post(SERVER_API_URL + "/api/qic/teamname", {
      teamname,
	  event_year,
      currentActiveEvent,
    });
    verifyAuthorization(response);
    return response;
  };
  const StageAttendDetails = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/stageattenddetails",
      {
        userId,
        value,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const QICSemifinalist = async (stageId, userId,event_year) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/semifinalist",
      { stageId, userId,event_year }
    );
    verifyAuthorization(response);
    return response;
  };
  const ShortlistStatusNextRound = async (userId, value, StageId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/shortliststatusnextround",
      {
        userId,
        value,
        StageId,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const QicUploadData = async (userId, StageId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/qicuploaddata",
      {
        userId,
        StageId,
        value,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const TeamdetailsdataQic = async (STeamName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/teamdetailQic",
      { STeamName }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditdatauserQic = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditdatauserQic",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };
  const TeamEditDetailsData = async (userId, value, event_year) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/teamNameEditGetData",
      { userId, value, event_year }
    );
    verifyAuthorization(response);
    return response;
  };
  const DocumentdetailsdataQic = async (
    userId,
    filterTeamName,
    filterStageName,
    filtershortlist,
    filterEventName
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/docdetailQic",
      {
        userId,
        filterTeamName,
        filterStageName,
        filtershortlist,
        filterEventName,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const RegisterCheck = async (userId,event_year) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/registercheck",
      { userId,event_year }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditTeamQic = async (value) => {
    const response = await Axios.post(SERVER_API_URL + "/api/qic/EditTeamQic", {
      value,
    });
    verifyAuthorization(response);
    return response;
  };
  const EditDocQic = async (userId, value, value1) => {
    const response = await Axios.post(SERVER_API_URL + "/api/qic/EditDocQic", {
      userId,
      value,
      value1,
    });
    verifyAuthorization(response);
    return response;
  };

  const EditSavedataQic = async (
    userId,
    ValueCheck,
    emaildata,
    fnamedata,
    isPosition,
    Team
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditsavedataQic",
      {
        userId,
        ValueCheck,
        emaildata,
        fnamedata,
        isPosition,
        Team,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditsavedTeamQic = async (
    ValueCheck,
    Week1,
    Week2,
    Week3,
    Week4,
    Week5,
    Week6,
    Week7,
    Week8,
    Team
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditsavedteamQic",
      {
        ValueCheck,
        Week1,
        Week2,
        Week3,
        Week4,
        Week5,
        Week6,
        Week7,
        Week8,
        Team,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditsaveddocQic = async (
    userId,
    ValueCheck,
    Status,
    Team,
    Stageid,
    teamId
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditsaveddocQic",
      {
        userId,
        ValueCheck,
        Status,
        Team,
        Stageid,
        teamId,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const SaveCreateTeam = async (userId, createTeam) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/SaveCreateTeam",
      { userId, createTeam }
    );
    verifyAuthorization(response);
    return response;
  };
  const SaveCreateEvent = async (
    userId,
    CreateEvent,
    EventDesc,
    eventStartDate,
    eventEndDate,
    EventYear
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/SaveCreateEvent",
      { userId, CreateEvent, EventDesc, eventStartDate, eventEndDate,EventYear }
    );
    verifyAuthorization(response);
    return response;
  };
  const SaveRenameTeam = async (userId, RenameTeam, OriginalTeamName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/SaveRenameTeam",
      { userId, RenameTeam, OriginalTeamName }
    );
    verifyAuthorization(response);
    return response;
  };

  const UserTeamDetails = async () => {
    const response = await Axios.get(SERVER_API_URL + "/api/teams");
    verifyAuthorization(response);
    return response;
  };
  const StageShortlistTeamDetails = async (userId,event_year) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/teamshortlistlist",
      { userId, event_year }
    );
    verifyAuthorization(response);
    return response;
  };

  const QicEventDetails = async (userId) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/eventdetails",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const QicEventDetailsSave = async (
    userId,
    CurEventName,
    startDate,
    endDate,
    CurEventYear,
    CurEventStatus,
    
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/eventdetailssave",
      { userId, CurEventName, startDate, endDate,CurEventYear,CurEventStatus }
    );
    verifyAuthorization(response);
    return response;
  };

  const EventName = async () => {
    const response = await Axios.get(SERVER_API_URL + "/api/qic/eventsname");
    verifyAuthorization(response);
    return response;
  };

  const QicStageDetailsSave = async (
    userId,
    startDate,
    endDate,
    valueCheck,
    event_year,
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/savedstagedata",
      { userId, startDate, endDate, valueCheck,event_year }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditStageData = async (userId, value,event_year) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/editstagedata",
      { userId, value, event_year }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditActiveEvent = async (userId, activestage, CurEventName) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/editactiveevent",
      { userId, activestage, CurEventName }
    );
    verifyAuthorization(response);
    return response;
  };
  const SendMessageToAdmins = async (
    userId,
    emails,
    mobilenumber,
    category,
    subject,
    content,
    attachment,
    userid,
    categoryIDs,
    helpstatus
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/Sendmessagetoadmins",
      {
        userId,
        emails,
        mobilenumber,
        category,
        subject,
        content,
        attachment,
        userid,
        categoryIDs,
        helpstatus,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const AddedQicUser = async (
    userId,
    auEmail,
    auHackothonFinisher,
    auTechnofundaaward,
    auCommunityDuration,
    auIndustry,
    currentActiveEvent
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/addedqicuser",
      {
        userId,
        auEmail,
        auHackothonFinisher,
        auTechnofundaaward,
        auCommunityDuration,
        auIndustry,
        currentActiveEvent,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const DeleteSelectedData = async (userId, value) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/deleteuserval",
      { userId, value }
    );
    verifyAuthorization(response);
    return response;
  };

  const BusinessRanking_Quiz_Questions = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/brquiz", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };
  const BusinessRanking_Quiz_Questions_Answer = async (
    userId,
    data,
    User_Id
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/brquizanswer",
      { userId, data, User_Id }
    );
    verifyAuthorization(response);
    return response;
  };
  const BusinessRanking_Quiz_Questions_Answer_Check = async (
    userId,
    User_Id
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/brquizanswercheck",
      { userId, User_Id }
    );
    verifyAuthorization(response);
    return response;
  };

  // Strong Calculator
  const Strong_Quiz_Questions = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/strongquiz", {
      userId,
    });
    verifyAuthorization(response);
    return response;
  };
  const Strong_Quiz_Questions_Answer = async (
    userId,
    data,
    User_Id
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/strongquizanswer",
      { userId, data, User_Id }
    );
    verifyAuthorization(response);
    return response;
  };
  const Strong_Quiz_Questions_Answer_Check = async (
    userId,
    User_Id
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/strongquizanswercheck",
      { userId, User_Id }
    );
    verifyAuthorization(response);
    return response;
  };

  const PriceMarker = async (
    userId,
    start_date,
    end_date,
    old_start_date,
    old_end_date,
    market_type
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/price_marker",
      { userId, start_date, end_date,old_start_date,old_end_date, market_type }
    );
    verifyAuthorization(response);
    return response;
  };

  const PriceMarkerSector = async (
    userId,
    start_date,
    end_date,
    old_start_date,
    old_end_date,
    index_type
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/price_marker_sector",
      { userId, start_date, end_date,old_start_date,old_end_date, index_type }
    );
    verifyAuthorization(response);
    return response;
  };

  const get10Y = async (
    userId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/10Y_yield",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const Voting_Insert = async (userId, teamid, type, isType,event_year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/votinginsert",
      {
        userId,
        teamid,
        type,
        isType,
        event_year,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const QicVotingDetails = async (userId,event_year) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/votingdetails",
      { userId ,event_year}
    );
    verifyAuthorization(response);
    return response;
  };
  const EditVotingQic = async (userId, event_year) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/editvotingqic",
      { userId, event_year }
    );
    verifyAuthorization(response);
    return response;
  };

 const QicVotingDetailsSave = async (
    userId,
    voteLimitSemifinaledit,
    likepointsemifinaledit,
    dislikePointSemifinaledit,
    startDateEdit,
    endDateEdit,
    event_year,
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/votingdetailssave",
      {
        userId,
        voteLimitSemifinaledit,
        likepointsemifinaledit,
        dislikePointSemifinaledit,
        startDateEdit,
        endDateEdit,
        event_year,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const QicVotingDetailsSaveFinal = async (
    userId,
    voteLimitfinaledit,
    likepointfinaledit,
    dislikepointfinaledit,
    startDateEdit,
    endDateEdit,
    event_year
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/votingdetailssavefinal",
      {
        userId,
        voteLimitfinaledit,
        likepointfinaledit,
        dislikepointfinaledit,
        startDateEdit,
        endDateEdit,
        event_year
      }
    );
    verifyAuthorization(response);
    return response;
  };
   const AllVotePoints = async (userId, user_id, types,event_year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/qic/votepoints", {
      userId,
      user_id,
      types,
      event_year,
    });
    verifyAuthorization(response);
    return response;
  };
  const PresentedByName = async (userId, Presentname) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/Event/Presentname",
      {
        userId,
        Presentname,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const ColorFlagsSet = async (userId, selectedValue) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/corewaitch/ColorFlagsSet",
      { userId, selectedValue }
    );
    verifyAuthorization(response);
    return response;
  };
  const AddCompanyflag = async (userId, selectMonth, selectedValue, selectYear) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/corewaitch/AddCompanyflag",
      { userId, selectMonth, selectedValue, selectYear }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditcoreWatchlist = async (
    userId,
    UserId,
    companyId,
    EditCompany_id,
    Editcompany_name,
    Editmonth,
    Editcolor,
    Timeframe,
    year
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/corewaitch/EditcoreWatchlist",
      {
        userId,
        UserId,
        companyId,
        EditCompany_id,
        Editcompany_name,
        Editmonth,
        Editcolor,
        Timeframe,
        year
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const DownLoadCoreWhatchList = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/corewaitch/DownLoadCoreWhatchList",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const DocumentdetailsdataQicWinner = async (
    userId,
    filterTeamName,
    filterStageName,
    filtershortlist,
    filterEventName
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/docdetailQicWinner",
      {
        userId,
        filterTeamName,
        filterStageName,
        filtershortlist,
        filterEventName,
      }
    );
    verifyAuthorization(response);
    return response;
  };


  const EditDocQicWinner = async (userId, value, value1) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditDocQicWinner",
      {
        userId,
        value,
        value1,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const EditsaveddocQicWinner = async (
    userId,
    ValueCheck,
    winnerStatus,
    Team,
    Stageid,
    teamId
  ) => {
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/EditsaveddocQicWinner",
      {
        userId,
        ValueCheck,
        winnerStatus,
        Team,
        Stageid,
        teamId,
      }
    );
    verifyAuthorization(response);
    return response;
  };
  const GetLoginDemoVideoLink = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/getlogindemovideoLink",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const GetDemoVideoLink = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/getdemovideoLink",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const Getquotes = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/user/getDailyQuotes");
    verifyAuthorization(response);
    return response;
  };

  const userQuotesRecord = async (userId, currentDate) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user-quotes/record",
      { userId, currentDate }
    );
    verifyAuthorization(response);
    return response;
  };

  const userQuotesCheck = async (userId, currentDate) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user-quotes/check",
      { userId,currentDate }
    );
    verifyAuthorization(response);
    return response;
  };

  const AddDeomvideolink = async (userId, user_id, linkname, videolink) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/adddeomvideolink",
      { userId, user_id, linkname, videolink }
    );
    verifyAuthorization(response);
    return response;
  };
  const EditDemoVideoLink = async (
    userId,
    user_id,
    linkname,
    videolink,
    EditId
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/editvideolink", {
      userId,
      user_id,
      linkname,
      videolink,
      EditId,
    });
    verifyAuthorization(response);
    return response;
  };
  const DeletVideoLink = async (userId, id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/deletvideolink", {
      userId,
      id,
    });
    verifyAuthorization(response);
    return response;
  };

  const VideolinkAddCoreWatichList = async (
    userId,
    compnayId,
    videoName,
    videoLink
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/videolinkAddCoreWatichList",
      { userId, compnayId, videoName, videoLink }
    );
    verifyAuthorization(response);
    return response;
  };
  // video link Data get
  const VideoLinkDatas = async (userId, companyId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/videoLinkDatas", {
      userId,
      companyId,
    });
    verifyAuthorization(response);
    return response;
  };
  //video link update
  const UpdateVideo_Link = async (userId, id, name, videolink) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/updatevideo_Link",
      { userId, id, name, videolink }
    );
    verifyAuthorization(response);
    return response;
  };

  // video link delete
  const DeleteVideoLink = async (userId, id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/deleteVideoLink", {
      userId,
      id,
    });
    verifyAuthorization(response);
    return response;
  };

  // pdf link add
  const PdflinkAddCoreWatichList = async (
    userId,
    compnayId,
    PdfName,
    PdfLink
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/PdflinkAddCoreWatichList",
      { userId, compnayId, PdfName, PdfLink }
    );
    verifyAuthorization(response);
    return response;
  };
  // Pdf link Data get
  const PdfLinkDatas = async (userId, companyId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/PdfLinkDatas", {
      userId,
      companyId,
    });
    verifyAuthorization(response);
    return response;
  };
  //Pdf link update
  const UpdatePdf_Link = async (userId, id, name, Pdflink) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/updatePdf_Link", {
      userId,
      id,
      name,
      Pdflink,
    });
    verifyAuthorization(response);
    return response;
  };

  // Pdf link delete
  const DeletePdfLink = async (userId, id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/deletePdfLink", {
      userId,
      id,
    });
    verifyAuthorization(response);
    return response;
  };

  const RewardsPointGet_user = async (userId, user_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/RewardsPointGet_user",
      { userId, user_id }
    );
    verifyAuthorization(response);
    return response;
  };

  const Sectorsdashboard = async (fillter, userId, year, quarter) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/sectordashboard", {
      fillter,
      userId,
      year,
      quarter,
    });
    verifyAuthorization(response);
    return response;
  };

  const Sectorupdatelikes = async (
    userId,
    likes,
    companyname,
    sector,
    id,
    year,
    quarter
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/sectorupdatelikes",
      { userId, likes, companyname, sector, id, year, quarter }
    );
    verifyAuthorization(response);
    return response;
  };
  const Sectorupdateuserlikesicon = async (
    comapnycategory,
    isLiked,
    isDisliked,
    sector,
    companys,
    id,
    userId,
    year,
    quarter
  ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/Sectorupdateuserlikesicon",
      {
        comapnycategory,
        isLiked,
        isDisliked,
        sector,
        companys,
        id,
        userId,
        year,
        quarter,
      }
    );
    verifyAuthorization(response);
    return response;
  };

  const ApiData = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/get_NSEdata");
    verifyAuthorization(response);
    return response;
  };

  const loginRewards = async (user_id) => {
    const response = await Axios.post(SERVER_API_URL + "/api/loginRewards", {
      user_id,
    });
    return response;
  };

  const Stockopedia = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/stockopedia",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const Stockopedia_post = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/stockopediaPost",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const Stockopedia_company = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/user/stockopediaCompany",
      { userId }
    );
    verifyAuthorization(response);
    // console.log("ressss",response);
    return response;
  };

  const IndexData = async () => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/get_NSEdata/IndexData");
    verifyAuthorization(response);
    return response;
  };
  const SectorIndexs = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Sector/Sectorindex",{userId});
    verifyAuthorization(response);
    return response;
  };

  const NoteDateCorewatichlist= async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/corewatichlist/Note_color_core_watchList",{userId});
    verifyAuthorization(response);
    return response;
  };

  const RewardCategorys= async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/Reward/RewardCategorys",{userId});
    verifyAuthorization(response);
    return response;
  };

  const IdeaDashboard= async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/IdeaDashboard",{userId});
    verifyAuthorization(response);
    return response;
  };
  const UserIdeaReward= async (userId,user_Id,year,week) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/SateliteWatchlist/UserIdeaReward",{userId,user_Id,year,week});
    verifyAuthorization(response);
    return response;
  };

  // Book hive
  const DeleteBook = async (id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/DeleteBook", {id});
    verifyAuthorization(response);
    return response;
  };

  const DeleteImage = async (id,file) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/delete-image", {id,file});
    verifyAuthorization(response);
    return response;
  };

  const DeleteFile = async (id,file) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/delete-File", {id,file});
    verifyAuthorization(response);
    return response;
  };

  
  const EditBook = async (data) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/EditBook", {data});
    verifyAuthorization(response);
    return response;
  };

  const ProfileImg = async (userId, memberId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await axios.post("/api/profile_img", {
      userId,
      memberId,
    });
    verifyAuthorization(response);
    return response;
  };

   //COMMUNITY NOTES
   const CompanyData = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/CompanyData", {userId});
    verifyAuthorization(response);
    return response;
  };

  const CompanyQuarterData = async (userId,company_id) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/CompanyQuarterData", {userId,company_id});
    verifyAuthorization(response);
    return response;
  };

  const UserNotes = async (userId,user_id,companyId,Quarter,Year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/UserNotes", {userId,user_id,companyId,Quarter,Year});
    verifyAuthorization(response);
    return response;
  };

  const UpdateLink = async (userId,sentiment,ranking,pptLink,materialLink,transcriptLink,companyId,Quarter,Year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/UpdateLink", {userId,sentiment,ranking,pptLink,materialLink,transcriptLink,companyId,Quarter,Year});
    verifyAuthorization(response);
    return response;
  };
  
  const UpdateNotes = async (userId,notes,companyId,Quarter,Year,NoteId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/UpdateNotes", {userId,notes,companyId,Quarter,Year,NoteId});
    verifyAuthorization(response);
    return response;
  };

  const AddUserNotes = async (userId,notes,companyId,Quarter,Year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/AddUserNotes", {userId,notes,companyId,Quarter,Year});
    verifyAuthorization(response);
    return response;
  };

  const SectorLeaderWatch = async (userId,year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/SectorLeaderWatch", {userId,year});
    verifyAuthorization(response);
    return response;
  };
  const SectorLeaderShortList = async (userId,year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/SectorLeaderShortList", {userId,year});
    verifyAuthorization(response);
    return response;
  };
  const EditSLWatchlist = async (userId,company_id,year,month,colors) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/EditSLWatchlist", {userId,company_id,year,month,colors});
    verifyAuthorization(response);
    return response;
  };
  const SLWatchListDelete = async (userId,company_id,year) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/SLWatchListDelete", {userId,company_id,year});
    verifyAuthorization(response);
    return response;
  };
  const SLWatchListInsert = async (userId,companyId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/SLWatchListInsert", {userId,companyId});
    verifyAuthorization(response);
    return response;
  };
  const ShortListCommentsAdd = async (userId,companyId,year,category,comments) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(SERVER_API_URL + "/api/ShortListCommentsAdd", {userId,companyId,year,category,comments});
    verifyAuthorization(response);
    return response;
  };

  const BulkUploaderWatchlist = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.get(SERVER_API_URL + "/api/SectorLeaderWatchlistData", {userId});
    verifyAuthorization(response);
    return response;
  };
  const Calculate2G3G = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.get(SERVER_API_URL + "/api/SectorLeaderShortList3G2G", {userId});
    verifyAuthorization(response);
    return response;
  };
  const SectorIdeasComment = async (userId, comment, company_id) => {
      const serviceToken = window.localStorage.getItem("serviceToken"); // Adjust based on where you store your token
      axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
      const response = await Axios.post(SERVER_API_URL + "/api/SectorIdeasComment",
        {userId, comment, company_id});
        verifyAuthorization(response);
        return response;
      };


      const CoreauditUserReward = async (userId,quarter,year) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(
          SERVER_API_URL + "/api/CoreauditUserReward",
          { userId,quarter,year }
        );
        verifyAuthorization(response);
        return response;
      };
	  
	  const getuserslist = async (userId) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/getuserslist", {userId});
        verifyAuthorization(response);
        return response;
      };
      const AddContributors = async (userId, Id, companyId, roleId) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
      
        try {
          const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/AddContributors", {
            userId,
            Id,
            companyId,
            roleId
          });
          verifyAuthorization(response); // Ensure this is used appropriately
          return response;
        } catch (error) {
          // Handle errors and rethrow with the appropriate message
          if (error.response && error.response.data && error.response.data.message) {
            throw new Error(error.response.data.message);
          } else {
            throw new Error("An error occurred while adding contributor.");
          }
        }
      };
      
      const showCompanyList = async (userId) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/showCompanyList", {userId});
        verifyAuthorization(response);
        return response;
      };
      const showContribtorData = async (userId) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/showContribtorData", {userId});
        verifyAuthorization(response);
        return response;
      };
      const deleteContribtorData = async (userId,id) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/deleteContribtorData", {userId,id});
        verifyAuthorization(response);
        return response;
      };
      const updateContributor = async (userId,userid, companyId, roleId, id) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
      
        try {
          const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/updateContributor", {
            userId,
            userid,
            companyId,
            roleId,
            id
          });
          
          verifyAuthorization(response); // Verify if the response is authorized
          return response;
        } catch (error) {
          // Throw error with detailed message from response
          if (error.response && error.response.data) {
            throw new Error(error.response.data.message || "An unknown error occurred.");
          } else {
            throw new Error(error.message || "An unknown error occurred.");
          }
        }
      };
      const showNotesData = async (userId) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/showNotesData", {userId});
        verifyAuthorization(response);
        return response;
      };
      const publishNote = async (userId,Id, roleId, companyId, noteId, quarter, year, financials, strategy, risk, outlook, others) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/publishNote", {userId,Id, roleId, companyId, noteId, quarter, year, financials, strategy, risk, outlook, others});
        verifyAuthorization(response);
        return response;
      };

      const updateNoteHistory = async (userId, companyId, noteId, quarter, year) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/updateNoteHistory", {userId, companyId, noteId, quarter, year});
        verifyAuthorization(response);
        return response;
      };
	  
	    const UpdateAbout = async (userId,companyId,about,websitelink) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/UpdateAbout", {userId,companyId,about,websitelink});
        verifyAuthorization(response);
        return response;
      };
	// const GetSectorchartdata = async (userId,sector_index_type,IndexTypes) => {
  //   const serviceToken = window.localStorage.getItem("serviceToken");
  //   Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  //   const response = await Axios.post(
  //     SERVER_API_URL + "/api/market/sector_chartdata",
  //     { userId,sector_index_type,IndexTypes }
  //   );
  //   verifyAuthorization(response);
  //   return response;
  // };
   const CompanyList = async (userId) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/CompanyList", {userId});
        verifyAuthorization(response);
        return response;
      };
      const AddCompany = async (userId,company_id,company_name) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/AddCompany", {userId,company_id,company_name});
        verifyAuthorization(response);
        return response;
      };
      const DeleteCompany = async (userId,company_id) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/CommunityNotes/DeleteCompany", {userId,company_id});
        verifyAuthorization(response);
        return response;
      };
      const updatemonthstatus = async (month ,year,color_id,company_id) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/updatemonthstatus", {month ,year,color_id,company_id});
        verifyAuthorization(response);
        return response;
      };
	    //  const coreIdeascuesupdate = async () => {
      //   const serviceToken = window.localStorage.getItem("serviceToken");
      //   Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
      //   const response = await Axios.get(SERVER_API_URL + "/api/coreIdeascuesupdate", {});
      //   verifyAuthorization(response);
      //   return response;
      // };
      const coreIdeastrendscoreupdate = async () => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.get(SERVER_API_URL + "/api/coreIdeastrendscoreupdate", {});
        verifyAuthorization(response);
        return response;
      };
      const Updateideacoretable = async () => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(SERVER_API_URL + "/api/SectorLeaderWatchlistData", {});
        verifyAuthorization(response);
        return response;
      };
	     const Top30IdeaCore = async (userId) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(
          SERVER_API_URL + "/api/Top30IdeaCore",
          { userId }
        );
        verifyAuthorization(response);
        return response;
      };

      const IdeaSatellite = async (userId, startDate , Turnover,  ThreShold, duration) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(
          SERVER_API_URL + "/api/SateliteWatchlist/IdeaSateliteCompany",
          { userId, startDate , Turnover, ThreShold, duration }
        );
        verifyAuthorization(response);
        return response;
      };

      const IdeaSatelliteSection = async (userId, startDate , duration, Turnover,  ThreShold, sectionTab ) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(
          SERVER_API_URL + "/api/IdeaSateliteCompanysection",
          { userId, startDate, duration, Turnover, ThreShold, sectionTab }
        );
        verifyAuthorization(response);
        return response;
      };
       const editAccess = async (userId) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(
          SERVER_API_URL + "/api/editAccess",
          { userId }
        );
        verifyAuthorization(response);
        return response;
      };

      const CMQuarterData = async (userId) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(
          SERVER_API_URL + "/api/CommunityNotes/CMQuarterData",
          { userId }
        );
        verifyAuthorization(response);
        return response;
      };

      const CMQuarterDataInsert = async (userId,year,quarter) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(
          SERVER_API_URL + "/api/CommunityNotes/CMQuarterDataInsert",
          { userId,year,quarter }
        );
        verifyAuthorization(response);
        return response;
      };

      const CMQuarterDataDelete = async (userId,rowId,year,quarter) => {
        const serviceToken = window.localStorage.getItem("serviceToken");
        Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
        const response = await Axios.post(
          SERVER_API_URL + "/api/CommunityNotes/CMQuarterDataDelete",
          { userId,rowId,year,quarter }
        );
        verifyAuthorization(response);
        return response;
      };
	   const IdeaSectorWc = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/sector/Idea_Sector_Wc",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const IdeaSatelliteOneWc = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/sector/Idea_SatelliteOne_Wc",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  // Wellness Event
  const WellnessEventGetData = async (userId, filtervalues) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/WellnessEventGetData",
      { userId, filtervalues }
    );
    verifyAuthorization(response);
    return response;
  };
  const WellnessEventAdd = async (userId, data) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/WellnessEventAdd",
      { userId, data }
    );
    verifyAuthorization(response);
    return response;
  };
  const WellnessEventEdit = async (userId, data, eventid) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/WellnessEventEdit",
      { userId, data, eventid }
    );
    verifyAuthorization(response);
    return response;
  };
  const WellnessEventDelete = async (userId, eventid ) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/WellnessEventDelete",
      { userId, eventid }
    );
    verifyAuthorization(response);
    return response;
  };



  // User Module
  const WellnessEventData = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/WellnessEventData",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const WellnessDailyEventData = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/WellnessDailyEventData",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  
    const QIC_popup_status = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/qic/QIC_popup_status",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };

  const MarketHolidays = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/sector/MarketHolidays",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const IdeaSatelliteUploadData = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/sector/IdeasSatelliteDB",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const SW_One_Percent_Download = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/SW_One_Percent_Download",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const TrendingSatelliteWatchlistDownload = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/TrendingSatelliteWatchlistDownload",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  const TrendingCorePortfolioDownload = async (userId) => {
    const serviceToken = window.localStorage.getItem("serviceToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    const response = await Axios.post(
      SERVER_API_URL + "/api/TrendingCorePortfolioDownload",
      { userId }
    );
    verifyAuthorization(response);
    return response;
  };
  
  
  return (
    <JWTContext.Provider
      value={{
         ...state,
        // - - - - Profile - - - - - 
         updateProfile,
         Alloccupationlist,
         resetPassword,

        IdeaSatelliteSection,
        IdeaSatelliteUploadData,
        MarketHolidays,
        IdeaSatellite,
        CoreauditUserReward,
        Calculate2G3G,
        BulkUploaderWatchlist,
        ShortListCommentsAdd,
        SLWatchListInsert,
        SLWatchListDelete,
        EditSLWatchlist,
        SectorLeaderWatch,
        SectorLeaderShortList,
        sectorWatchlist,
        SW_One_Percent,
        DeleteBook,
        DeleteImage, 
        DeleteFile, 
        EditBook,
        UserIdeaReward,
        IdeaDashboard,
        RewardCategorys,
        NoteDateCorewatichlist,
        IndexData,
        SectorIndexs,
        loginRewards,
        ApiData,
        Sectorsdashboard,
        Sectorupdatelikes,
        Sectorupdateuserlikesicon,
        RewardsPointGet_user,
        DeleteVideoLink,
        UpdateVideo_Link,
        VideoLinkDatas,
        VideolinkAddCoreWatichList,
        PdfLinkDatas,
        PdflinkAddCoreWatichList,
        DeletePdfLink,
        UpdatePdf_Link,
        GetLoginDemoVideoLink,
        GetDemoVideoLink,
        Getquotes,
        userQuotesRecord,
        userQuotesCheck,
        AddDeomvideolink,
        EditDemoVideoLink,
        DeletVideoLink,
        OnboardingCheckList_Delete_OR_Edit,
        OnboardingCheckList_Added,
        DownLoadCoreWhatchList,
        EditcoreWatchlist,
        AddCompanyflag,
        ColorFlagsSet,
        PresentedByName,
        stockalertviewdata,
        StockAlertList,
        stockalertUpdate,
        stockalertDelete,
        DeleteExpired,
        stockInsert,
        stocklist,
        Displayresponse,
        UpdateResponse,
        GetcategorylistTool,
        GetdataHelpManagement,
        updateCoreAudit,
        RewardsRollingFetchData,
        RewardsRollingInsert,
        ProcessUpdate,
        CheckStatusOfLearning,
        DeleteLearningHistory,
        InsertLearningHistory,
       
        CheckTotalCountOfLearning,
        GetLearing,
        login,
        logout,
        register,
       
       
        verifyotp,
        newPassword,
        Profiledata,
        registerOtpVerify,
        SociallinkView,
        updateSociallink,
        AllUsers,
        EventReport,
        Addfriend,
        Removefriend,
        MyFollower,
        MyFriends,
        MyGrouplist,
        Statistics,
        MemberProfile,
        charitylist,
        watchlist,
        charitylistdata,
        deletecharitydata,
        Insertcharitylist,
        Insertwatchlist,
        weekdata1,
        weekly,
        watchlistdata,
        vivekwatchlistdata,
        weeklist,
        DeleteWatchlist,
        SectorsList,
        InsertSectorwatchlist,
        Sectorwatchlistdata,
        DeleteSectorWatchlist,
        UserSectorRewards,
        HallOfFame,
        Coreauditcompanylist,
        Coreauditdatalist,
        insertCoreAudit,
        deleteCoreAudit,
        WeeklyRitualsQuestions,
        WeeklyRitualsAnswer,
        WeeklyRitualsAnswerCheck,
        QuarterlyRitualsQuestions,
        QuarterlyRitualsAnswer,
        QuarterlyRitualsAnswerCheck,
        Editcharitydata,
        Resourceslist,
        ResearchList,
        TemplateList,
        TechnoList,
        ApplicationsList,
        LearningList,
        Quantum_Quiz_Questions,
        Quantum_insertdata,
        checkuserIddata,
        checkanswerdata,
        Qscore,
        Email,
        Rewards_chart,
        TopTwentyuserDetailsBaseOnRewaerdPoint,
        TrendingCorePortfolio,
        TrendingSatelliteWatchlist,
        RewardsPoint,
        habits,
        habitchart,
        CheckStatusOfHabit,
        GetHabitsNamelist,
        InsertHabit,
        Pastevent,
        Futurevent,
        Todayevent,
        GetAttendance,
        InsertAttendance,
        updateVideoLink,
        CalendarEventsList,
        AttendanceChartData,
        profilevisibilityInsert,
        profilevisibility,
        UserDetails,
        Editdatauser,
        EditSavedata,
        Adddatauser,
        Csvreport,
        CharityReport,
        Watchlist,
        CoreAuditReportdata,
        LoginReport,
        NotloginReportdata,
        QuantumQuizdata,
        updateEvent,
        DeleteSchedule,
        GetEventsList,
        AddEvent,
        AlertReport,
        GroupnameList,
        EventList,
        GetcategoryWithoutLogin,
        ContactInsertWithoutLogin,
        Getcategory,
        ContactInsert,
        corewatchlist,
        coreInsertwatchlist,
        corewatchlisthistory,
        corewatchlisthistoryAdmin,
        DeleteCoreWatchlist,
        EditCoreWatchlistgetdata,
        GetHabitsList,
        Gethabitsdata,
        MonthlyLineChart,
        handleChangedeletedata,
        CatcusDeleteCoreWatchlist,
        Catcuswatchlisthistory,
        GetAllCompanyforStockAlert,
        CreateStockalert,
        GetStockalertCount,
        GetStockalertList,
        StockAlertDelete,
        UpdateStockAlert,
        GetMarketcategory,
        GetMarketchartdata,
        GetSectorchartdata,
        GetSectortabledata,
        GetMarketcategory,
        Gamefications,
        Onboarding,
        Marketdata,
        Insertedata,
        CoreauditCashdatalist,
        UpdateCoreauditCashdata,
        DeleteAllSchedule,
        UserDetailsQic,
        MemberDetails,
        TeamdetailsdataQic,
        EditdatauserQic,
        TeamEditDetailsData,
        RegisterCheck,
        EditTeamQic,
        EditSavedataQic,
        EditsavedTeamQic,
        SaveCreateTeam,
        SaveRenameTeam,
        UserTeamDetails,
        QicEventDetails,
        EventName,
        QicEventDetailsSave,
        SaveCreateEvent,
        DocumentdetailsdataQic,
        EditDocQic,
        EditsaveddocQic,
        StageShortlistTeamDetails,
        StageAttendDetails,
        ShortlistStatusNextRound,
        QicUploadData,
        QICSemifinalist,
        QicStageDetailsSave,
        EditStageData,
        EditActiveEvent,
        SendMessageToAdmins,
        DeleteSelectedData,
        AddedQicUser,
        BusinessRanking_Quiz_Questions,
        BusinessRanking_Quiz_Questions_Answer,
        BusinessRanking_Quiz_Questions_Answer_Check,
        Strong_Quiz_Questions,
        Strong_Quiz_Questions_Answer,
        Strong_Quiz_Questions_Answer_Check,
        PriceMarker,
        PriceMarkerSector,
        get10Y,
        Voting_Insert,
        QicVotingDetails,
        EditVotingQic,
        QicVotingDetailsSave,
        QicVotingDetailsSaveFinal,
        AllVotePoints,
        DocumentdetailsdataQicWinner,
        EditDocQicWinner,
        EditsaveddocQicWinner,
        Stockopedia_company,
        Stockopedia_post,
        Stockopedia,
        ProfileImg,
        CompanyData,
        CompanyQuarterData,
        UserNotes,
        UpdateLink,
        UpdateNotes,
        AddUserNotes,
		SectorIdeasComment,
		getuserslist,
        AddContributors,
        showCompanyList,
        showContribtorData,
        deleteContribtorData,
        updateContributor,
        showNotesData,
        publishNote,
        updateNoteHistory,
		UpdateAbout,
		// GetSectorchartdata,
		CompanyList,
        AddCompany,
        DeleteCompany,
        updatemonthstatus,
		// coreIdeascuesupdate,
    coreIdeastrendscoreupdate,
        Updateideacoretable,
		Top30IdeaCore,
	  	editAccess,
        CMQuarterData,
        CMQuarterDataInsert,
        CMQuarterDataDelete,
	    IdeaSectorWc,
        IdeaSatelliteOneWc,
        WellnessEventGetData,
        WellnessEventAdd,
        WellnessEventEdit,
        WellnessEventDelete,
        WellnessEventData,
        WellnessDailyEventData,
        QIC_popup_status,
        SW_One_Percent_Download,
        TrendingSatelliteWatchlistDownload,
        TrendingCorePortfolioDownload,		
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
