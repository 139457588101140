// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconDashboard,
  IconDeviceAnalytics,
  IconPictureInPicture,
  IconShieldLock,
  IconChartInfographic,
  IconSquareCheck,
  IconClipboardCheck,
  IconChartArcs,
  IconList,
  IconCalendarEvent,
  IconChecklist
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconPictureInPicture,
  IconShieldLock,
  IconChartInfographic,
  IconSquareCheck,
  IconClipboardCheck,
  IconChartArcs,
  IconList,
  IconCalendarEvent,
  IconChecklist
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Stocks = {
  id: "Stocks",
  title: <>Stock<sup style={{ fontSize: "1.0em", fontWeight: "bold" }}> AI</sup>{" "}</>,
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: "CoreIdeas",
      title: <FormattedMessage id="Ideas Core" />,
      type: "item",
      url: "/CoreIdeas",
      icon: icons.IconChecklist,
      breadcrumbs: false,
    },
    {
      id: 'IdeaSatellite',
      title: <FormattedMessage id="IdeaSatellite" />,
      type: 'item',
      url: '/IdeaSatellite',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: "IdeaSatelliteOne",
      title: <FormattedMessage id="Idea Satellite One" />,
      type: "item",
      url: "/IdeaSatelliteOne",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "Alerts",
      title: <>Alerts<sup style={{ color: 'red' }}><b>(Beta)</b></sup></>,
      type: "item",
      url: "/StockAlert",
      icon: icons.IconShieldLock,
      breadcrumbs: false,
    },
  ],
};

export default Stocks;
