import { lazy } from "react";
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";



// - - - - - - - Admin - - - - - - - - 
const User_Management = Loadable(lazy(() => import("Collab-community-views/dashboard/User_management")));
const Help_Management = Loadable(lazy(() => import("Adminviews/dashboard/Help_management")));
const SectorDashboardWatchlist = Loadable(lazy(()=>import('Collab-community-views/dashboard/SectorDashboardWatchList/component/AllWatchlist.js')));
const CommunityNotesManagement = Loadable(lazy(() => import("Adminviews/dashboard/CMCompanyManagement")));
const CommunityNotesContributors = Loadable(lazy(() => import("Collab-community-views/dashboard/CommunityNotesContributors")));
const AddBookHive =Loadable(lazy(()=>import('Collab-community-views/dashboard/BookHive_Management/ADD/AddBookHive.js')));
const EditBookHive =Loadable(lazy(()=>import('Collab-community-views/dashboard/BookHive_Management/EDIT/EditBookHive.js')));

// - - - - - - - Dashboard - - - - - - - - 
const PersonalDashboard = Loadable(lazy(() => import("Collab-community-views/dashboard/Personal-Dashboard")));
const CommunityDashboard = Loadable(lazy(() => import("Collab-community-views/CommunityDashboard")));

// - - - - - - - Resource - - - - - - - - 
const CommunityNotes = Loadable(lazy(() => import("Collab-community-views/dashboard/StockoPediaNew/index.js")));
const BookHive = Loadable(lazy(()=>import('Collab-community-views/dashboard/BookHive/index.js')));

// - - - - - - MarketDashboard - - - - - -
const MarketCap = Loadable(lazy(() => import("Collab-community-views/dashboard/Market")));
const NSEIndex = Loadable(lazy(() => import("Collab-community-views/dashboard/NSEIndex")));
const IdeaSector=Loadable(lazy(()=>import('Collab-community-views/dashboard/IdeaSector/index.js')));

// - - - - - - Other - - - - -
const ContactUS = Loadable(lazy(() => import("Collab-community-views/contact-us")));
const Help_Response = Loadable(lazy(() => import("Collab-community-views/dashboard/HelpResponse")));


// - - - - - - - Profile - - - - -
const Profile = Loadable(lazy(() => import("Collab-community-views/profile")));
const CollabProfile = Loadable(lazy(() => import("Collab-community-views/profile")));



// - - - - - - Stocks - - - - - 
const CoreIdeas = Loadable(lazy(() => import("Collab-community-views/dashboard/SectorDashboard/index")));
const IdeaSatellite=Loadable(lazy(()=>import('Collab-community-views/dashboard/IdeaSatellite/index.js')));
const IdeaSatelliteOne=Loadable(lazy(()=>import('Collab-community-views/dashboard/IdeaSatelliteOne/index.js')));
const StockAlert = Loadable(lazy(() => import("Collab-community-views/dashboard/StockAlert_new")));


// - - - - - Waiting for Delete - - - - - - 
const Resources = Loadable(lazy(() => import("Collab-community-views/dashboard/Resources")));
const Calculator = Loadable(lazy(() => import("Collab-community-views/dashboard/Calculator")));
const PortfolioSizing = Loadable(lazy(() => import("Collab-community-views/dashboard/PortfolioSizingCal/index.js")));
const ValuationCalculator = Loadable(lazy(() => import("Collab-community-views/dashboard/ValuationCalculator/index.js")));
const PyramidCalculator = Loadable(lazy(() => import("Collab-community-views/dashboard/PyramidCalculator/index.js")));
const PortfolioAccelerator = Loadable(lazy(() => import("Collab-community-views/dashboard/PortfolioAccelerator/index.js")));
const DhruvaQuickGuide = Loadable(lazy(() => import("Collab-community-views/dashboard/QuickGuide/index.js")));


const Quiz2 = Loadable(lazy(() => import("Collab-community-views/QIC/Strong_Calculator/Quiz")));
const Quiz = Loadable(lazy(() => import("Collab-community-views/QIC/Business_Ranking_Calculator/Quiz")));


const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [

    // - - - - - - - Admin - - - - - - 
    {
      path: "/User_Management",
      element: <User_Management />,
    },
    {
      path: "/Help_Management",
      element: <Help_Management />,
    },
    {
      path:'/SectorDashboardWatchlist',
      element:<SectorDashboardWatchlist/>
    },
    {
      path:'/CommunityNotesManagement',
      element:<CommunityNotesManagement/>
    },
    {
      path:'/CommunityNotesContributors',
      element:<CommunityNotesContributors/>
    },
    {
      path:'/AddBookHive',
      element:<AddBookHive/>
    },
    {
      path:'/EditBookHive',
      element:<EditBookHive/>
    },


   // - - - - - - Dashboard - - - - - - 
    {
      path: "/Personal-Dashboard",
      element: <PersonalDashboard />,
    },
    {
      path: "/CommunityDashboard",
      element: <CommunityDashboard />,
    },


  // - - - - - - Resource - - - - - - 
    {
      path: "/CommunityNotes",
      element: <CommunityNotes/>,
    },
    {
      path: '/BookHive',
      element: <BookHive/>
    },


  // - - - - - - - MarketDashboard - - - - - - - 
  {
    path: "/Market",
    element: <MarketCap />,
  },
  {
    path: "/NSEIndex",
    element: <NSEIndex />,
  },
  {
    path: "/IdeaSector",
    element: <IdeaSector />,
  },

  // - - - - - Other - - - - - 
  {
    path: "/contact-us",
    element: <ContactUS />,
  },
  {
    path: "/HelpResponse",
    element: <Help_Response />,
  },


 // - - - - - Profile - - - - - 
  {
    path: "/Profile",
    element: <Profile />,
  }, 
  {
      path: "/profile/:tab",
      element: <CollabProfile />,
   },

  // - - - - Stocks - - - - - 
  {
    path: "/CoreIdeas",
    element: <CoreIdeas />,
  },
  {
    path:'/IdeaSatellite',
    element:<IdeaSatellite/>
  },
  {
    path: "/IdeaSatelliteOne",
    element: <IdeaSatelliteOne />,
  },
  {
    path: "/StockAlert",
    element: <StockAlert />,
  },


// - - - - - End Stock I

   
  
  // Waiting for datele 
    {
      path: "/Resources",
      element: <Resources />,
    },
    {
      path: "/calculator",
      element: <Calculator />,
    },
    {
      path: "/Calculator/PortfolioSizing",
      element: <PortfolioSizing />,
    },
    {
      path: "/Calculator/Valuation_Calculator",
      element: <ValuationCalculator />,
    },
    {
      path: "/Calculator/Pyramid",
      element: <PyramidCalculator />,
    },
    {
      path: "/Calculator/Portfolio_Accelerator",
      element: <PortfolioAccelerator />,
    },
    {
      path:"/quickguide",
      element:<DhruvaQuickGuide />
    },

    {
      path: "/qic/Strong_Calculator",
      element: <Quiz2 />,
    },
    {
      path: "/qic/businessranking_calculator",
      element: <Quiz />,
    },
  ],
};

export default MainRoutes;
