// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, useMediaQuery, Typography, IconButton, Button, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import useAuth from 'hooks/useAuth';
// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';
// assets
import { IconMenu2 } from '@tabler/icons';
import Chip from './Chip';
import Blink from 'react-blink-text';
import Index from './DemoVideolink';
import CalculatedIndex from './CalculaterIcon.js';
import DailyQuotes from './quotesDaily';
import Onboarding from './Onboarding';
import QuickGuide from './QuickGuide';
import { useNavigate } from 'react-router-dom';
import HelpCenterOutlined from '@mui/icons-material/HelpCenterOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { SupportAgentOutlined } from '@mui/icons-material';
import EmojiObjectsOutlined from '@mui/icons-material/EmojiObjectsOutlined';





// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
  const theme = useTheme();

  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { drawerOpen } = useSelector((state) => state.menu);
  const flag = useSelector((state) => state.flag.value)
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { layout } = useConfig();
  const Usersid = window.localStorage.getItem('users');
  const { MemberProfile } = useAuth();
  const val = JSON.parse(Usersid);
  const NId = val.id;
  const Space = " ";
  const [city, setcity] = useState([]);
  const [email, setemail] = useState([]);
  const [fname, setfname] = useState([]);
  const [lname, setlname] = useState([]);
  const [profileProgress, setprofileProgress] = useState([]);
  const [imgurl, setimgurl] = useState([]);
  const userInfoData = async (value) => {
    const list = await MemberProfile(NId, NId);
    setemail(list.data.data.profile_details[0].email);
    setcity(list.data.data.profile_details[0].city);
    setlname(list.data.data.profile_details[0].lname);
    setfname(list.data.data.profile_details[0].fname);
    setimgurl(list.data.data.profile_details[0].imgurl);
    setprofileProgress(list.data.data.profile_details[0].profileProgress);
  }

  useEffect(() => {
    // getData();
    userInfoData();
  }, [flag]);
  const fullname = fname + Space + lname;

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/profile/personal');
  };

  const handleNavigateGuide = () => {
    navigate('/quickguide');
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleNavigateHelpTicket = () => {
    navigate('/contact-us');
    handleCloseMenu();
  };

  const handleNavigateHelpResponse = () => {
    navigate('/HelpResponse');
    handleCloseMenu();
  };

  const handleNavigateQIC = () => {
    navigate("/qic/dashboard2025?year=2025");
  };

  return (
    <>
      <Box
        sx={{
          width: 45,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >


        {layout === LAYOUT_CONST.VERTICAL_LAYOUT || (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: 'hidden',
              transition: 'all .2s ease-in-out',
              background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
              color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
              '&:hover': {
                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
              }
            }}
            onClick={() => dispatch(openDrawer(!drawerOpen))}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        ) : null}
      </Box>

      <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
        <Typography component="span" variant="h2"> Stocki</Typography>
      </Box>

      <Box sx={{ marginRight: "15px", display: { xs: 'none', sm: 'block' } }}>
        <Typography component="span" variant="h3">
          <Blink color='red' text={profileProgress < 100 ?
            <Button onClick={handleNavigate}>
              <Chip label='Complete your profile details' chipcolor="error" size="17px" />
            </Button> : ' '} fontSize='20' />
        </Typography>
      </Box>
      {/* <Box
  sx={{
    marginRight: "1px",
    cursor: "pointer",
  }}
  onClick={handleNavigateQIC}
>
  <Typography>
    <Chip
      label={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            position: "relative",
            width: "100%", // Ensure the entire width is used
          }}
        >
          <Box
            component="span"
            sx={{
              fontWeight: "bold",
              color: "black",
              fontSize: "15px",
              cursor: "pointer",
            }}
          >
            QIC 2025&nbsp;
          </Box>
          <Box
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              padding: "4px 8px", // Adjust padding for better positioning
              backgroundColor: "#0666e5",
              color: "white",
              fontSize: "10px",
              fontWeight: "bold",
              animation: "blink 2s infinite",
              position: "relative",
              height: "20px", // Adjusted to align with the chip height
              minWidth: "20px",
              cursor: "pointer",
              "&:hover": {
                animation: "none",
              },
            }}
          >
            LIVE
          </Box>
        </Box>
      }
      sx={{
        backgroundColor: "white",
        borderRadius: "10px",
        cursor: "pointer",
        border: "2px solid #c9c9c9",
        padding: "27px 10px", 
        margin: "0px 7px 0px 5px",
        position: "relative",
        fontSize: "18px",
        overflow: "visible",
        animation: "borderAnimation 2s infinite",
        "&:hover": {
          backgroundColor: "white",
          border: "1px solid #000",
        },
        "@keyframes borderAnimation": {
          "0%": {
            borderColor: "#c9c9c9",
          },
          "50%": {
            borderColor: "#0767e6",
          },
          "100%": {
            borderColor: "#c9c9c9",
          },
        },
      }}
    />
  </Typography>
      </Box> */}
      {/* <Box sx={{ 
                     
                    display: 'flex', 
                    border: '2px solid #c9c9c9', // Base border color
                    padding: '10px', // Padding inside the Box
                    borderRadius: '10px', // Optional: rounded corners
                    margin: '0px 7px 0px 5px',
                    position: 'relative', 
                    animation: 'borderAnimation 2s infinite', // Apply animation
                    '@keyframes borderAnimation': {
                        '0%': {
                        borderColor: '#c9c9c9', // Base color
                        },
                        '50%': {
                        borderColor: '#0767e6', // Color when animated
                        },
                        '100%': {
                        borderColor: '#c9c9c9', // Back to base color
                        }
                    }
                    }}>
                    <Box sx={{ display: { xs: 'none', sm: 'block' }, marginTop: '7px', marginRight: '5px' }}>
                        <Typography variant='h4'>Getting Started</Typography>
                    </Box>
                    <Index />
                    <Onboarding />
                    <QuickGuide />
            </Box> */}

      {/* <NotificationSection /> */}
      {/* {/* <DailyQuotes /> */}
      <IconButton
        variant="rounded"
        ref={anchorRef}
        aria-haspopup="true"
        color="inherit"
        onClick={handleOpenMenu}
        sx={{
          ...theme.typography.commonAvatar,
          ...theme.typography.mediumAvatar,
          transition: 'all .2s ease-in-out',
          background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
          color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
          '&[aria-controls="menu-list-grow"],&:hover': {
            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
          }
        }}
      >
        <SupportAgentOutlined fontSize="medium" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleNavigateHelpTicket}>
          <ListItemIcon>
            <HelpOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Help</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleNavigateHelpResponse}>
          <ListItemIcon>
            <FeedbackOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Help Response</ListItemText>
        </MenuItem>
      </Menu>

      <CalculatedIndex />

      <Tooltip title="Quick Guide">
        <IconButton
          variant="rounded"
          sx={{
            ...theme.typography.commonAvatar,
            ...theme.typography.mediumAvatar,
            transition: 'all .2s ease-in-out',
            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
            color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
            '&[aria-controls="menu-list-grow"],&:hover': {
              background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.secondary.dark,
              color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.secondary.light
            },
            mr: 2
          }}
          ref={anchorRef}
          aria-haspopup="true"
          color="inherit"
          onClick={handleNavigateGuide}
        >
          <EmojiObjectsOutlined size={24} />
        </IconButton>
      </Tooltip>
      <Box sx={{ display: { xs: 'none', sm: 'block' }, marginRight: '7px' }}>
        <Typography variant='h3'>{fullname}</Typography>
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'block' }, marginRight: { xs: '13px', sm: '7px' } }}>
        <ProfileSection fullname={fullname} />
      </Box>
    </>
  );
};

export default Header;
