// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconClipboardCheck, IconDashboard, IconDeviceAnalytics, IconChartArcs, IconClipboardList, IconChartInfographic, IconKey, IconBug, IconPhoneCall, IconSquareCheck, IconHomeQuestion, IconYoga } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconChartArcs,
    IconClipboardList,
    IconChartInfographic,
    IconKey,
    IconBug,
    IconPhoneCall,
    IconSquareCheck,
    IconClipboardCheck,
    IconHomeQuestion,
    IconYoga
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Mind = {
    id: 'MIND',
    title: <FormattedMessage id="MIND" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: []

};

export default Mind;
