import Admin from './Admin';
import dashboard from './dashboard';
import Resource from './Resources';
import MarketDashboard from './MarketDashboard';
import Mind from './Mind';
import Others from './other';
import Profile from './Profile';
import Stocks from './Stocks';

// ==============================|| MENU ITEMS ||============================== //
const Usersid = window.localStorage.getItem("users");

// const Suserid = useSelector((state)=>state.counter.value);

let menuItems = {};
menuItems = {
    items: [Profile, dashboard, Stocks, Mind, Resource, Others]
};

if (Usersid) {
    const { role_id,_} = JSON.parse(Usersid);
    if (role_id !== 3 || role_id !== 1 || role_id !== 2) {
        menuItems = {
            items: [Profile, MarketDashboard, Stocks, Resource]
        };
    }
    if (role_id === 3 || role_id === 1 || role_id === 2) {
        menuItems = {
            items: [Profile, MarketDashboard, Stocks, Resource, Admin]
        };
    }

}

export default menuItems;


