import { lazy } from "react";

// project imports
import GuestGuard from "utils/route-guard/GuestGuard";
import MinimalLayout from "layout/MinimalLayout";
import NavMotion from "layout/NavMotion";
import Loadable from "ui-component/Loadable";
import NotFound from "Collab-community-views/dashboard/404Page";

// login routing
const AuthLogin = Loadable(
  lazy(() => import("views/pages/authentication/authentication copy/Login"))
);
const AuthRegister = Loadable(
  lazy(() => import("views/pages/authentication/authentication copy/Register"))
);
const AuthForgotPassword = Loadable(
  lazy(() => import("views/pages/authentication/authentication copy/ForgotPassword"))
);
const Terms = Loadable(
  lazy(() => import("views/pages/authentication/auth-forms/terms"))
);
const Privacy = Loadable(
  lazy(() => import("views/pages/authentication/auth-forms/privacy"))
);
const EventError = Loadable(
  lazy(() => import("Collab-community-views/dashboard/404Page/Event"))
);
// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: "/",
  element: (
    <NavMotion>
      <GuestGuard>
        <MinimalLayout />
      </GuestGuard>
    </NavMotion>
  ),
  children: [
    {
      path: "/login",
      element: <AuthLogin />,
    },
    // {
    //   path: "/register",
    //   element: <AuthRegister />,
    // },
    {
      path: "/forgot-password",
      element: <AuthForgotPassword />,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/errorpage",
      element: <EventError />,
    },
    { path: "*", element: <NotFound /> },
  ],
};

export default LoginRoutes;
