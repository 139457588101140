// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconClipboardCheck, IconPictureInPicture, IconForms, IconChartArcs, IconDatabaseExport, IconApps, IconCalendarEvent, IconUsers, IconUserX, IconVideo, IconChecklist } from '@tabler/icons';
import Book from '@mui/icons-material/Book';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconClipboardCheck,
    IconPictureInPicture,
    IconForms,
    IconChartArcs,
    IconDatabaseExport,
    IconApps,
    IconUsers,
    IconUserX,
    IconCalendarEvent,
    IconVideo,
    IconChecklist
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Admin = {
    id: 'Admin Dashboard',
    title: <FormattedMessage id="Admin Dashboard" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [

        {
            id: 'Manage users',
            title: <FormattedMessage id="Manage users" />,
            type: 'item',
            url: '/User_Management',
            icon: icons.IconForms,
            breadcrumbs: false
        },
        {
            id: 'Help Management',
            title: <FormattedMessage id="Help Management" />,
            type: 'item',
            url: '/Help_Management',
            icon: icons.IconClipboardCheck,
            breadcrumbs: false
        },
        {
            id: 'SectorLeaderWatchlistAdmin',
            title: <FormattedMessage id="Watchlist Admin" />,
            type: 'item',
            url: '/SectorDashboardWatchlist',
            icon: icons.IconChecklist,
            breadcrumbs: false
        },
        {
            id: 'Community Notes Admin',
            title: <FormattedMessage id="Community Notes Admin" />,
            type: 'item',
            url: '/CommunityNotesManagement',
            icon: icons.IconClipboardCheck,
            breadcrumbs: false
        },
        {
            id: 'Community Notes Contributors',
            title: <FormattedMessage id="CM Notes Publish" />,
            type: 'item',
            url: '/CommunityNotesContributors',
            icon: icons.IconClipboardCheck,
            breadcrumbs: false
        },
        {
            id: "BookHive Management",
            title: <FormattedMessage id="BookHive Management" />,
            type: "collapse",
            icon: Book,
            breadcrumbs: false,
            children: [
                {
                    id: "AddBook",
                    title: <FormattedMessage id="Add Book - Category" />,
                    type: "item",
                    url: "/AddBookHive",
                    icon: icons.IconApps,
                    breadcrumbs: false,
                },
                {
                    id: "EditBook",
                    title: <FormattedMessage id="Edit Book" />,
                    type: "item",
                    url: "/EditBookHive",
                    icon: icons.IconApps,
                    breadcrumbs: false,
                },
            ]
        },
    ]
};

export default Admin;
