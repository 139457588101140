// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconDeviceAnalytics, IconClipboardCheck, IconPictureInPicture, IconForms, IconChartArcs, IconDatabaseExport, IconApps, IconCalendarEvent, IconUsers, IconUserX, IconVideo, IconBook, IconChecklist } from '@tabler/icons';


import Book from "@mui/icons-material/Book";
// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconClipboardCheck,
  IconPictureInPicture,
  IconForms,
  IconChartArcs,
  IconDatabaseExport,
  IconApps,
  IconUsers,
  IconUserX,
  IconCalendarEvent,
  IconVideo,
  IconChecklist,
  IconBook
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const MarketDashboard = {
  id: "Market Dashboard",
  title: (
    <>
      Market<sup style={{ fontSize: "1.0em", fontWeight: "bold" }}> AI</sup>{" "}
    </>
  ),
  icon: icons.IconDashboard,
  type: "group",
  children: [
    {
      id: 'Market_Outlook',
      title: <FormattedMessage id="Market Outlook" />,
      type: 'item',
      url: '/Market',
      icon: icons.IconDashboard,
      breadcrumbs: false
    },
    {
      id: 'Sector_Outlook',
      title: <FormattedMessage id="Sector Outlook" />,
      type: 'item',
      url: '/NSEIndex',
      icon: icons.IconChecklist,
      breadcrumbs: false
    },
    {
      id: "IdeaSector",
      title: <FormattedMessage id="Idea Sector" />,
      type: "item",
      url: "/IdeaSector",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
  ]
};

export default MarketDashboard;
