// third-party
import { FormattedMessage } from 'react-intl';
import Link from '@mui/material/Link';

// assets
import { IconDashboard, IconDeviceAnalytics, IconBrandChrome, IconHelp, IconSitemap, IconTools, IconShieldLock, IconBook, IconBooks, IconCalculator, IconCalendarEvent } from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconBrandChrome,
    IconHelp,
    IconSitemap,
    IconTools,
    IconShieldLock,
    IconBook,
    IconBooks,
    IconCalendarEvent
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Resource = {
    id: 'Resource',
    title: <FormattedMessage id="Resources" />,
    icon: icons.IconDashboard,
    type: 'group',
    children: [
        {
            id: "Stockopedia",
            title: <FormattedMessage id="Community Notes" />,
            type: "item",
            url: "/CommunityNotes",
            icon: icons.IconCalendarEvent,
            breadcrumbs: false,
        },
        {
            id: 'BookHive',
            title: <FormattedMessage id="BookHive" />,
            type: 'item',
            url: '/BookHive',
            icon: icons.IconBook,
            breadcrumbs: false
        },

    ]

};

export default Resource;
