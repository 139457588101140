import { lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import Loadable from "ui-component/Loadable";
import useAuth from "hooks/useAuth";
import NotFound from "Collab-community-views/dashboard/404Page";
import EventError from "Collab-community-views/dashboard/404Page/Event";

const PagesLanding = Loadable(
  lazy(() => import("views/pages/authentication/authentication copy/Login"))
);

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isLoggedIn } = useAuth();
  return useRoutes([
    { path: "/", element: <PagesLanding /> },
    AuthenticationRoutes,
    LoginRoutes,
    MainRoutes,
    // ...(isLoggedIn ? [MainRoutes] : []),
    // { path: "*", element: <EventError /> },
    // { path: "*", element: <Navigate to="/" /> },
  ]);
}
